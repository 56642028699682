import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { verifyToken } from "../Common/AuthToken";



const AuthProtected = (props) => {
  const [status, setStatus] = useState(null);
  const storedToken = localStorage.getItem('auth_token');

  useEffect(() => {
    async function fetchData() {
      try {
        if (storedToken) {
          let user = {
            token: storedToken
          }
          let token_res = await verifyToken(user);
          console.log('Token verification response:', token_res); // Log the response

          if (token_res.status === 1) {
            setStatus(1);
          } else {
            setStatus(0);
          }
        } else {
          console.log('No stored token found');
          setStatus(0);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setStatus(0);
      }
    }
    fetchData()
  }, [storedToken]);

  if (status === 0) {
    localStorage.removeItem("auth_token");
    return (
      <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
    );
  }

  if (JSON.parse(sessionStorage.getItem('user'))?.registration_status === 0) {
    return (
      <Navigate to={{ pathname: "/newlyregistered", state: { from: props.location } }} />
    );
  }

  return <>{props.children}</>;
};

export { AuthProtected };
