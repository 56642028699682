import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Button } from 'reactstrap';
import Footer from '../Layouts/Footer';

const Home = () => {
  const navigate = useNavigate();

  const handleGetStarted = () => {
    navigate('/register');
  };

  const handleLogin = () => {
    navigate('/login');
  };

  return (
    <div>
      <Container className="my-5 text-center">
        <div className="home-jumbotron">
          <h1 className="display-4">Welcome to the RISE Storage Platform</h1>
          <p className="lead">
            The RISE storage platform is a robust long-term data management solution designed to securely transfer data from VAST short-term storage. It features a 500TB capacity, extensive data access via CLI, web interfaces, and REST APIs. RISE enables administrators to manage users, projects, and storage quotas, while providing data visualization, online data analysis, and detailed resource usage tracking. This platform ensures efficient, scalable, and secure data storage and management for the Electron Microscopy Core (EMC) resource center.
          </p>
          <hr className="my-4" />
          <Row className="justify-content-center">
            <Col xs="auto">
              <Button color="primary" size="lg" onClick={handleGetStarted}>
                Get Started
              </Button>
            </Col>
            <Col xs="auto">
              <Button color="secondary" size="lg" onClick={handleLogin}>
                Login
              </Button>
            </Col>
          </Row>
        </div>
      </Container>
      <Footer />
    </div>
  );
};

export default Home;
