import React from 'react';
import './App.css';

import Route from './Routes/Index'

function App() {
  return (
    <React.Fragment>
      <Route/>
    </React.Fragment>
  );
}

export default App;
