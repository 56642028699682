import React from "react";
// import { Navigate } from "react-router-dom";

import Login from "../Pages/Login"
import Register from "../Pages/Register"
import Dashboard from "../Pages/Dashboard"
import Homepage from "../Pages/HomePage";
import NewlyRegisteredUsers from "../Pages/newlyReg"
import UserManagement from "../Pages/UserManagement"
import Instruments from "../Pages/Instruments"
import Projects from "../Pages/Projects"
import Project from "../Pages/Project"
import Storage from "../Pages/Storage"
import ImageView from "../Pages/Image"
import ScanImage from "../Pages/ScanImage"
import Processes from "../Pages/Processes";
import Statistics from "../Pages/Statistics";

const publicRoutes = [
    { path: "/newlyregistered", component: <NewlyRegisteredUsers /> },
    { path: "/homepage", component: <Homepage /> },
    { path: "/login", component: <Login /> },
    { path: "/register", component: <Register /> },
]
const authProtectedRoutes = [
    { path: "/", component: <Dashboard /> },
    { path: "/dashboard", component: <Dashboard /> },
    { path: "/user-management", component: <UserManagement/> },
    { path: "/instruments", component: <Instruments /> },
    { path: "/projects", component: <Projects /> },
    { path: "/project/:id", component: <Project /> },
    { path: "/image-view/:params", component: <ImageView /> },
    { path: "/storage", component: <Storage /> },
    { path: "/scan-image/:projectId", component: <ScanImage /> },
    { path: "/statistics", component: <Statistics /> },
    { path: "/processes", component: <Processes /> }
]

export { publicRoutes, authProtectedRoutes }
