import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, Alert } from 'reactstrap';
import axios from 'axios';
import { verifyToken } from '../Common/AuthToken';

const Processes = () => {
  const [modal, setModal] = useState(false);
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState('');
  const [tool, setTool] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [operatingSystem, setOperatingSystem] = useState('');
  const [error, setError] = useState('');
  const [successModal, setSuccessModal] = useState(false);
  const [userData, setUserData] = useState([]);
  const storedToken = localStorage.getItem('auth_token');

  const toggleModal = () => setModal(!modal);
  const toggleSuccessModal = () => setSuccessModal(!successModal);

  useEffect(() => {
    async function fetchData() {
      try {
        let user = {
          token: storedToken
        };
        let token_res = await verifyToken(user);
        setUserData([token_res?.data]);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    fetchData();
  }, [storedToken]);

  useEffect(() => {
    const fetchProjects = async () => {
      if (userData.length === 0) return;

      try {
        const config = {
          method: 'POST',
          url: process.env.REACT_APP_API_SERVER.concat('/allProjects'),
          headers: {
            'Content-Type': 'application/json',
          },
          data: { user_email: userData[0]?.email || "" },
        };

        const response = await axios.request(config);
        setProjects(response.data.data);
      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    };

    fetchProjects();
  }, [userData]);

  // const createProcess = async (selectedProject, tool, password, operatingSystem) => {
  //   let config = {
  //     method: 'post',
  //     url: process.env.REACT_APP_API_SERVER.concat('/newProcess'),
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //     data: JSON.stringify({ selectedProject, tool, password, operatingSystem })
  //   };
  //   await axios.request(config)
  //     .then(async (response) => {
  //       const { status, msgType, msg, data } = response.data;
  //       switch (status) {
  //         case 1:
  //           // setSuccess(msgType)
  //           break;
  //         case 2:
  //           // setErrorMsg(msg)
  //           break;

  //         default:
  //           break;
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!tool || !password || !confirmPassword || !operatingSystem || !selectedProject) {
      setError('Please fill in all the fields.');
    } else if (password !== confirmPassword) {
      setError('The entered passwords should match.');
    } else {
      setError('');
      // Handle form submission
      console.log({
        selectedProject,
        tool,
        password,
        operatingSystem
      });
      // Close form modal after submission
      setModal(false);
      // Show success dialog
      setSuccessModal(true);
    }
  };

  return (
    <div>
      <Button color="primary" onClick={toggleModal}>Create Process</Button>
      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Create Process</ModalHeader>
        <ModalBody>
          {error && <Alert color="danger">{error}</Alert>}
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Label for="toolSelect">Choose the desired tool</Label>
              <Input type="select" name="tool" id="toolSelect" value={tool} onChange={e => setTool(e.target.value)}>
                <option value="">Select a tool</option>
                <option value="rclone">rclone</option>
                <option value="tool2">tool 2</option>
                <option value="tool3">tool 3</option>
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="projectSelect">Choose the desired project</Label>
              <Input type="select" name="project" id="projectSelect" value={selectedProject} onChange={e => setSelectedProject(e.target.value)}>
                <option value="">Select a project</option>
                {projects.map(project => (
                  <option key={project.uniqueId} value={project.uniqueId}>{project.projectName}</option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="password">Create a Password</Label>
              <Input type="password" name="password" id="password" value={password} onChange={e => setPassword(e.target.value)} />
            </FormGroup>
            <FormGroup>
              <Label for="confirmPassword">Confirm Password</Label>
              <Input type="password" name="confirmPassword" id="confirmPassword" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} />
            </FormGroup>
            <FormGroup>
              <Label for="osSelect">Choose your operating system</Label>
              <Input type="select" name="operatingSystem" id="osSelect" value={operatingSystem} onChange={e => setOperatingSystem(e.target.value)}>
                <option value="">Select an OS</option>
                <option value="Windows">Windows</option>
                <option value="MacOS">MacOS</option>
                <option value="Linux">Linux</option>
              </Input>
            </FormGroup>
            <Button color="primary" type="submit">Submit</Button>{' '}
            <Button color="secondary" onClick={toggleModal}>Cancel</Button>
          </Form>
        </ModalBody>
      </Modal>
      <Modal isOpen={successModal} toggle={toggleSuccessModal}>
        <ModalHeader toggle={toggleSuccessModal}>Success</ModalHeader>
        <ModalBody>
          Your request has been successfully submitted.
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggleSuccessModal}>OK</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default Processes;
