import React, { useState, useCallback, useEffect } from 'react';
import { Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { Bar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import axios from 'axios';

Chart.register(...registerables);

const Statistics = () => {
  const [loginData, setLoginData] = useState({});
  const [registrationData, setRegistrationData] = useState({});
  const [loginTimeFrame, setLoginTimeFrame] = useState('last 24 hours');
  const [registrationTimeFrame, setRegistrationTimeFrame] = useState('last 24 hours');
  const [showGraph, setShowGraph] = useState(false);
  const [showRegistrationGraph, setShowRegistrationGraph] = useState(false);
  const [loginDropdownOpen, setLoginDropdownOpen] = useState(false);
  const [registrationDropdownOpen, setRegistrationDropdownOpen] = useState(false);

  const processLoginData = useCallback((data) => {
    const now = new Date();
    const oneDay = 24 * 60 * 60 * 1000;
    const oneWeek = 7 * oneDay;
    const thirtyDays = 30 * oneDay;

    const groupedData = data.reduce((acc, log) => {
      const date = new Date(log.createdAt);
      const day = date.toISOString().split('T')[0];
      if (!acc[day]) {
        acc[day] = { day, admin: 0, researcher: 0, user: 0 };
      }
      acc[day].user += 1; // Adjust this line to count admin and researcher if needed
      return acc;
    }, {});

    const filterData = (timeLimit) => {
      return Object.values(groupedData).filter(({ day }) => {
        const dayDate = new Date(day);
        return now - dayDate <= timeLimit;
      });
    };

    return {
      'last 24 hours': filterData(oneDay),
      'last one week': filterData(oneWeek),
      'last 30 days': filterData(thirtyDays),
    };
  }, []);

  const processRegistrationData = useCallback((data) => {
    const now = new Date();
    const oneDay = 24 * 60 * 60 * 1000;
    const oneWeek = 7 * oneDay;
    const thirtyDays = 30 * oneDay;

    const groupedData = data.reduce((acc, reg) => {
      const date = new Date(reg.createdAt);
      const day = date.toISOString().split('T')[0];
      if (!acc[day]) {
        acc[day] = { day, total: 0, approved: 0, admin: 0, researcher: 0, user: 0 };
      }
      acc[day].total += 1;
      if (reg.registration_status === 1) {
        acc[day].approved += 1;
        if (reg.admin_status === 2) acc[day].admin += 1;
        if (reg.admin_status === 1) acc[day].researcher += 1;
        if (reg.admin_status === 0) acc[day].user += 1;
      }
      return acc;
    }, {});

    const filterData = (timeLimit) => {
      return Object.values(groupedData).filter(({ day }) => {
        const dayDate = new Date(day);
        return now - dayDate <= timeLimit;
      });
    };

    return {
      'last 24 hours': filterData(oneDay),
      'last one week': filterData(oneWeek),
      'last 30 days': filterData(thirtyDays),
    };
  }, []);

  useEffect(() => {
    const updateCache = async () => {
      try {
        const loginResponse = await axios.get('/userLog');
        const loginData = processLoginData(loginResponse.data.data);
        setLoginData(loginData);

        const registrationResponse = await axios.get('/userRegistrations');
        const registrationData = processRegistrationData(registrationResponse.data.data);
        setRegistrationData(registrationData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    updateCache();
  }, [processLoginData, processRegistrationData]);

  const handleTimeFrameChange = (timeFrame, type) => {
    if (type === 'login') {
      setLoginTimeFrame(timeFrame);
    } else if (type === 'registration') {
      setRegistrationTimeFrame(timeFrame);
    }
  };

  const loginChartData = loginData[loginTimeFrame] ? {
    labels: loginData[loginTimeFrame].map(data => data.day),
    datasets: [
      {
        label: 'Admin',
        data: loginData[loginTimeFrame].map(data => data.admin),
        backgroundColor: 'rgba(75,192,192,1)',
      },
      {
        label: 'Researcher',
        data: loginData[loginTimeFrame].map(data => data.researcher),
        backgroundColor: 'rgba(153,102,255,1)',
      },
      {
        label: 'User',
        data: loginData[loginTimeFrame].map(data => data.user),
        backgroundColor: 'rgba(255,159,64,1)',
      }
    ]
  } : {};

  const registrationChartData = registrationData[registrationTimeFrame] ? {
    labels: registrationData[registrationTimeFrame].map(data => data.day),
    datasets: [
      {
        label: 'Total New User Registrations',
        data: registrationData[registrationTimeFrame].map(data => data.total),
        backgroundColor: 'rgba(75,192,192,1)',
      },
      {
        label: 'Approved New User Registrations',
        data: registrationData[registrationTimeFrame].map(data => data.approved),
        backgroundColor: 'rgba(153,102,255,1)',
      }
    ]
  } : {};

  const registrationChartOptions = {
    scales: {
      y: {
        beginAtZero: true
      }
    },
    plugins: {
      tooltip: {
        callbacks: {
          afterLabel: function (tooltipItem) {
            const data = registrationData[registrationTimeFrame][tooltipItem.dataIndex];
            return [
              `Admin: ${data.admin}`,
              `Researcher: ${data.researcher}`,
              `User: ${data.user}`
            ];
          }
        }
      }
    }
  };

  return (
    <div>
      <Button color="primary" onClick={() => setShowGraph(!showGraph)}>
        {showGraph ? "Hide User Logins" : "Show User Logins"}
      </Button>
      {showGraph && (
        <>
          <h5 className='fw-bold mb-3 mt-5'>User Logins</h5>
          <Dropdown isOpen={loginDropdownOpen} toggle={() => setLoginDropdownOpen(!loginDropdownOpen)}>
            <DropdownToggle caret>
              {loginTimeFrame}
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => handleTimeFrameChange('last 24 hours', 'login')}>Last 24 Hours</DropdownItem>
              <DropdownItem onClick={() => handleTimeFrameChange('last one week', 'login')}>Last One Week</DropdownItem>
              <DropdownItem onClick={() => handleTimeFrameChange('last 30 days', 'login')}>Last 30 Days</DropdownItem>
            </DropdownMenu>
          </Dropdown>
          {loginData[loginTimeFrame] ? (
            <Bar
              data={loginChartData}
              options={{
                scales: {
                  y: {
                    beginAtZero: true
                  }
                }
              }}
            />
          ) : (
            <p>No data available for the selected time frame.</p>
          )}
        </>
      )}
      <Button color="primary" onClick={() => setShowRegistrationGraph(!showRegistrationGraph)}>
        {showRegistrationGraph ? "Hide New User Registrations" : "Show New User Registrations"}
      </Button>
      {showRegistrationGraph && (
        <>
          <h5 className='fw-bold mb-3 mt-5'>New User Registrations</h5>
          <Dropdown isOpen={registrationDropdownOpen} toggle={() => setRegistrationDropdownOpen(!registrationDropdownOpen)}>
            <DropdownToggle caret>
              {registrationTimeFrame}
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => handleTimeFrameChange('last 24 hours', 'registration')}>Last 24 Hours</DropdownItem>
              <DropdownItem onClick={() => handleTimeFrameChange('last one week', 'registration')}>Last One Week</DropdownItem>
              <DropdownItem onClick={() => handleTimeFrameChange('last 30 days', 'registration')}>Last 30 Days</DropdownItem>
            </DropdownMenu>
          </Dropdown>
          {registrationData[registrationTimeFrame] ? (
            <Bar
              data={registrationChartData}
              options={registrationChartOptions}
            />
          ) : (
            <p>No data available for the selected time frame.</p>
          )}
        </>
      )}
    </div>
  );
};

export default Statistics;
