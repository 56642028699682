import React, { useState } from 'react';
import { Container, Row, Col, Button, Collapse } from 'reactstrap';
import Footer from '../Layouts/Footer';

const NewlyRegisteredUsers = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div>
      <Container className="my-5 text-center">
        <div className="new-user-jumbotron">
          <h1 className="display-4">Welcome to the RISE Storage Platform</h1>
          <p className="lead">
            Thank you for registering! To access the portal, you need to be accepted by an admin. In the meantime, feel free to explore what RISE storage can do.
          </p>
          <hr className="my-4" />
          <Row className="justify-content-center">
            <Col xs="auto">
              <Button color="info" size="lg" onClick={toggle}>
                Learn More
              </Button>
            </Col>
          </Row>
          <Collapse isOpen={isOpen} className="mt-4">
            <div>
              <p className="lead">
                The RISE storage platform is a robust long-term data management solution designed to securely transfer data from VAST short-term storage. It features a 500TB capacity, extensive data access via CLI, web interfaces, and REST APIs. RISE enables administrators to manage users, projects, and storage quotas, while providing data visualization, online data analysis, and detailed resource usage tracking. This platform ensures efficient, scalable, and secure data storage and management for the Electron Microscopy Core (EMC) resource center.
              </p>
            </div>
          </Collapse>
        </div>
      </Container>
      <Footer />
    </div>
  );
};

export default NewlyRegisteredUsers;
